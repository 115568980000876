export const AllPermissions = {
    Dashboard_Tab: "KBGD8",

    Store_View_Tab: "PF1RL",
    Store_Add: "60KJA",
    Store_Edit: "IH1YW",
    Store_Is_Active: "GJAC7",

    Content_Tab: "H73VT",

    Brand_Tab: "MPK89",
    Brand_Add: "HI8QP",
    Brand_Edit: "4QB57",

    Product_Type_Tab: "VPOWT",
    Product_Type_Add: "E8BR9",
    Product_Type_Edit: "9SE8C",
    Product_Type_Delete: "K42YA",

    Product_Material_Tab: "AS1QO",
    Product_Material_Add: "6HP7O",
    Product_Material_Edit: "DUAYQ",
    Product_Material_Delete: "6VS0J",

    Sw_color_Tab: "KJB9I",
    Sw_color_Add: "C7TBG",
    Sw_color_Edit: "8756B",
    Sw_color_Delete: "R6GD2",

    Shape_Tab: "56ID0",
    Shape_Add: "A8FEQ",
    Shape_Edit: "TVEP8",
    Shape_Delete: "S8D3O",

    // new create permission
    Size_Tab: "AZ3X7",
    Bridge_Size_Tab: "KQ9LP",
    Temple_Size_Tab: "JU8NM",
    Power_Tab: "BW4TY",

    // for Un Recognize
    Un_Recognize_Tab: "XF2ZK",

    // for Un Recognize Brand Tab
    Un_Brand_Tab: "LD8QW",

    // for Un Recognize Product Tab
    Un_Product_Tab: "TE1YQ",

    // for Un Recognize Inventory Tab
    Un_Inventory_Tab: "PU8BX",
    Un_Inventory_Pending_Tab: "RA3CJ",
    Un_Inventory_Approved_Tab: "WK5ME",
    Un_Inventory_Search_Tab: "HF2YN",
    Un_Inventory_Audit_Tab: "HW3QF",

    // for Un Recognize Barcode Tab
    Un_Barcode: "QL7UI",
    Un_Barcode_Pending: "QL7ZR",
    Un_Barcode_Approved: "VA6CX",
    Un_Move_Out_Tab: "XM1DO",
    Un_Barcode_Price_Updated: "GE5KU",
    Un_Lost_Barcode_Tab: "NR4JL",
    Un_Lost_Barcode_History_Tab: "BV2TC",

    // for recognize tab
    Recognize_Tab: "YP9XM",


    //    end recognize

    // store section
    Store_Tab: "KL6PT",

    //website
    Website_Form: "WS6ZT",
    Feedback_Form: "FE3DX",
    Retailer_Form: "RT1MN",
    //end

    //e-commerce
    E_Commerce: "WS6ZZ",
    E_Commerce_Coupon: "RT12N",
    //end

    Product_Tab: "RPJ3T",
    Product_Add: "SDKLH",

    // Product_Edit: 'JQMUC',
    // Product_Details_View: 'L2F6R',
    // Product_Is_Approve: 'ICXA8',

    Product_Rework_Tab: "F153B",
    Product_Rework_Download_Button: "2UQS0",

    Product_Pending_Tab: "DB146",
    Product_Pending_Download_Button: "KK62G",

    Product_Approved_Tab: "E1CC8",
    Product_Approved_Download_Button: "KN6SE",
    Product_Approved_Edit_Option: "UH2P1",

    Product_Bulk_Edit_Tab: "D655A",
    Product_Bulk_Upload_Tab: "81Z0A",
    Product_Bulk_Filter_Tab: "91BFT",
    Product_Bulk_Approve_Tab: "PBAT1",

    Inventory_Tab: "5RXW4",
    // Inventory_Add: 'TRA1S',
    // Inventory_Details_View: '1BD3L',
    // Inventory_Is_Approve: 'Z1I2W',

    Inventory_Pending_Tab: "6JED8",
    Inventory_Approved_Tab: "3RTR8",
    Inventory_Search_Tab: "HOA1V",
    Inventory_MinQty_Tab: "IJT69",

    Sub_Admin_Tab: "7MJZO",

    Supplier_Tab: "ALDJH",

    Barcode_Tab: "LWPVH",
    Barcode_Pending: "29RPB",
    Barcode_Approved: "8Q0JN",
    Price_Updated_Barcode_Tab: "2PUBT",
    Barcode_Moved_Out_Tab: "8BMOT",
    Barcode_Lost: "QV9XR",
    Barcode_Lost_History: "DU5KL",



    Brand_Allocation_Tab: "0YXU0",
    Allocation_Tab: "XK2NE",
    Allocated_Tab: "KJQXL",

    Movement_Tab: "QUG6S",
    Movement_Movement_Tab: "II6GN",
    Movement_Pending_Tab: "J6LDZ",
    Movement_InProcess_Tab: "YJEMK",
    Movement_Delivered_Tab: "FIR3R",
    Movement_Cancelled_Tab: "ABSII",
    Settlement_Note: "MTSN1",
    Settlement_History: "MTSNH",

    Roles_Tab: "DVIYH",

    Permissions_Tab: "YCD89",

    Accounts_Tab: "YXMK2",
    ALL_ENTRIES_Tab: "B3PC7",
    Sub_Accounts_Tab: "Z97O0",
    General_Accounts_Tab: "DA8M8",
    Trail_Balance_Tab: "WE2LU",
    Gl_Report_Tab: "P3YTJ",
    Transaction_Tab: "NYQUE",
    Store_Balance_Tab: "D5EFP",
    Add_Voucher: 'KS68T',

    Report_Tab: "8IM0P",
    Report_Customer_Report_Tab: "CSIMN",
    Report_GST_Input_Report_Tab: "FZKQH",
    Report_Store_Sales_Report_Tab: "JEIB8",
    Report_Store_Sales_Return_Report_Tab: "QWERT",
    Report_Inventory_Movement_Report_Tab: "QS06L",
    Report_Inventory_Report_Tab: "AUKV5",
    Report_GST_Output_Report_Tab: "ED5TB",
    Report_Store_Report_Tab: "I1JRS",
    Report_Inventory_Addition_Tab: "Y5X0P",
    Report_Invoicing_Report_Tab: "6ED70",
    Report_Invoicing_Return_Report_Tab: "TREWQ",
    Report_Barcode_Audit_Report_Tab: "Q79DX",
    Report_Ownership_Transfer_Report_Tab: 'OSJ8P',

    Procurement_Tab: "0MDAO",

    Loyalty_Points_Tab: "2GR7E",

    // setting tab
    Settings_Tab: "SYVJD",
    Location_Grouping_Tab: "L56GT",
    Location_Matrix_Tab: "L65MT",
    Other_Tab: "R87NJ",

    Warranty_Tab: "N1O9R",

    Inventory_Audit_Tab: "HITU1",

};
