export const validationRules: any = {
  supplier_id: {
    label: {
      required: true,
    },
  },
  // invoice_file: {
  //   required: false,
  // },
  store_id: {
    label: {
      required: true,
    },
  },
  category: {
    label: {
      required: true,
    },
  },
  supplier_invoice_number: {
    required: true,
  },
  supplier_invoice_date: {
    required: true,
  },
  actual_invoice: {
    required: true,
  },
  remark: {
    required: true,
  },
}
