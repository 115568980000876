import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { onlineOrder } = Dictionary

const OnlineOrderService = {
  // Online order list
  onlineOrderList: async (paramsObj: {}) => {
    return axios.get(BASE_URL + onlineOrder.pendingOrder(), { params: paramsObj })
  },

  cancelledOnlineOrder:async(id:string)=>{
    return axios.delete(BASE_URL + onlineOrder.updatedOnlineOrder(id))
  },

  downloadOrder: async (id:string) => {
    return axios.get(BASE_URL + onlineOrder.downloadOnlineOrder(id))
  },

}
export default OnlineOrderService