import React, { useState, useEffect, useContext } from "react";
import organisms from "../../../../components/organisms";
import { HeaderHeading } from "../../../../Context";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues';
import service from "../../../../api/services";
import { getDropdown, getParams } from "../../../../constants/utils";
import { useSearchParams } from "react-router-dom";
import constant from "../../../../constants/constant";

const { MoveOutBarcode } = organisms

const Index = () => {
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('status-moveout-type') == constant.BRAND_CATEGORY[0]?.value)
    const [moverOutBarcode, setMoveOutBarcode] = useState<any>()
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [isContactLensProduct, setIsContactLensProduct] = useState<boolean>(false)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const [storeData, setStoreData] = useState([]);
    const [search, setSearch] = useState('');
    const [storeId, setStoreId] = useState<string[]>([])

    const getStoreList = async () => {
        try {
            let storeResponse = await service.inventory.storeView()
            if (storeResponse.status === 200) {
                setStoreData(getDropdown(storeResponse?.data?.data, 'store_location', 'store_name'))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getMoveOutBarcode = async () => {
        const storeIds = storeId?.map((item: any) => item.value)
        const data = {
            search: search,
            page: currentPage,
            limit: limitPerPage,
            barcode_status: 2,
            store_ids: storeIds,
            is_recognised: typeValue,
            is_contact_lens: isContactLensProduct
        }
        try {
            let response = await service.barcodeStatus.barcodeMoveOutService(data)
            if (response.status === 200) {
                setMoveOutBarcode(response.data)
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    useEffect(() => {
        let tempValue: any = getParams('status-moveout-type')
        setTypeValue(tempValue == constant.BRAND_CATEGORY[0]?.value)
        setMoveOutBarcode({})
        setCurrentPage(Default_Page_Number)
        setLimitPerPage(Default_Page_Limit)
        setSearch('')
    }, [searchParams])

    useEffect(() => {
        setHeader("Inventory - Pending/Rework")
        setSidebar(false)
        getMoveOutBarcode()
    }, [currentPage, typeValue,isContactLensProduct,limitPerPage, storeId, search])

    // useEffect(() => {
    //     setCurrentPage(1)
    //     if (currentPage == 1 && initialLoading) {
    //         getMoveOutBarcode()
    //     }
    //     setInitialLoading(true)
    // }, [limitPerPage, storeId, search])

    useEffect(() => {
        setHeader("Barcode - Move Out");
        getStoreList()
    }, [])

    return (
        <Wrapper>
            <MoveOutBarcode
                moverOutBarcode={moverOutBarcode}
                setCurrentPage={(page: number) => setCurrentPage(page)}
                setLimitPerPage={(limit: number) => setLimitPerPage(limit)}
                currentPage={currentPage}
                limit={limitPerPage}
                setSearch={(searchValue: string) => setSearch(searchValue)}
                storeData={storeData}
                handleChange={(value: any) => setStoreId(value)}
                isContactLensProduct={isContactLensProduct}
                setIsContactLensProduct={setIsContactLensProduct}
            />
        </Wrapper>
    )
}

export default Index