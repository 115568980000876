import React from "react";
import atoms from "../../../atoms";
import { numberWithCommas, convertToCommaFormat } from "../../../../constants/utils";
import moment from 'moment'
import constant from "../../../../constants/constant";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineFileDownload } from "react-icons/md";
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import { AiOutlineEye } from "react-icons/ai";


const { Table, Pagination, Search, Image, Button } = atoms

const Index = ({ data, handleChange, handleView, setOrderItemId, setSearch }: any) => {

    const columns = [
        { name: 'Date', selector: (row: any) => `${row?.createdAt ? moment(row?.createdAt).format('DD/MM/YYYY') : "--"}`, sortable: true },
        { name: 'Specxweb Online Order No.', selector: (row: any) => row?.specxweb_order_ref_no ? row.specxweb_order_ref_no : "-" },
        { name: 'Date of Invoice', selector: (row: any) => `${row?.updatedAt ? moment(row?.updatedAt).format('DD/MM/YYYY') : "--"}` },
        { name: 'Invoice No.', selector: (row: any) => row?.invoice_number ? row.invoice_number : "-" },
        { name: 'Name', selector: (row: any) => row?.customer?.name ? row.customer?.name : "-" },
        { name: 'Mobile No.', selector: (row: any) => row?.customer?.mobile ? row.customer?.mobile : "-" },
        { name: 'Store Name', selector: (row: any) => row?.store?.store_name ? row.store?.store_name : "-" },
        { name: 'Delivery', selector: (row: any) => row?.store_pick_up ? "Store pickup" : "Home pickup" },
        { name: 'Total Amount', selector: (row: any) => row?.total_amount ? `₹${convertToCommaFormat(row?.total_amount)}` : '-', width: '150px' },

        {
            name: 'Total Items', selector: (row: any) => <>
                <div>
                    <p className='mb-1 ' >Total Order : {row.total_order_items}</p>
                    <p className='mb-1 '>Delivered : {row.total_delivered_order_items}</p>
                    <p className='mb-1 '>Pending : {row.total_pending_order_items}</p>

                </div>
            </>, width: '140px'
        },
        {
            name: 'Actions', selector: (row: any) => row?.action, minWidth: "15%"
        },
    ];


    return (
        <>
            <div className="row align-items-center">
                <div className="col-6">
                    <Search className="" searchCallBack={(e: string) => { setSearch(e); handleChange("currentPage", 1) }} />
                </div>
            </div>
            {(data?.listData?.data && data?.listData?.data?.length > 0) ? <div>
                <Table
                    className="adjustable-row-height"
                    columns={columns}
                    data={data?.listData?.data?.map((item: any, index: number) => {
                        return ({
                            ...item,
                            action: <>
                                <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                    <Button className="table-edit-buttons fs-14 fw-500"
                                        onClick={() => { handleView(item?.id); setOrderItemId(item?.id) }}>
                                        <div className='d-flex align-items-center'>
                                            <AiOutlineEye className='fs-20' />
                                            <span className='ms-2 mb-0'>View</span>
                                        </div>
                                    </Button>
                                </div>
                            </>,
                        })
                    })}
                />
            </div> : <div className='justify-content-center d-flex mt-5 '>There are no records to display</div>}
            <div>
                <Pagination
                    totalCount={data?.listData?.count}
                    currentPage={data?.currentPage}
                    totalPages={data?.listData?.totalPages}
                    onChangePage={(page: any) => handleChange("currentPage", page)}
                    setLimit={(e: number) => handleChange("limitPerPage", e)}
                    limit={data?.limitPerPage}
                />
            </div>
        </>
    )
}

export default Index