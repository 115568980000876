import React, { useState, useEffect } from 'react'
import { numberWithCommas } from '../../../constants/utils'
import atoms from '../../atoms'

const { Button } = atoms

const Index = ({ productCartData, checkout, storeBillingDetail }: any) => {
    const [total, setTotal] = useState({
        totalSRP: 0,
        totalLandingCost: 0,
    })
    useEffect(() => {
        countTotalSRP()
    }, [productCartData])

    const countTotalSRP = () => {
        if (productCartData) {
            const { totalSRP, totalLandingCost } = productCartData.reduce((previousValue: any, currentValue: any) => {
                const productSRP = currentValue?.product_srp || 0;
                const landingDiscount = parseFloat(currentValue?.landing_cost) || 0;

                previousValue.totalSRP += productSRP;
                previousValue.totalLandingCost += landingDiscount;
                return previousValue;
            }, { totalSRP: 0, totalLandingCost: 0 });
            setTotal({ totalSRP, totalLandingCost });
        }
    }

    const payableAmount = (Data: any, landingCost: number) => {
        let sum = (landingCost) +
            (Number(Data?.inventory_account_balance) +
                Number(Data?.current_account_balance) +
                Number(Data?.deposit_account_balance) +
                Number(Data?.pending_amount) +
                Number(Data?.online_receipt_account_balance)
            )

        return parseFloat(sum.toFixed(2))
    }

    return (
        <div className="checkout-card">
            <h2 className='mb-0 fs-18 fw-600'>Billing Summary</h2>

            <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
                <h2 className='mb-0 fs-14 fw-500'>Current Total Order Value ( Total SRP - ₹{numberWithCommas(total?.totalSRP)} )</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat(Number(total.totalLandingCost).toFixed(2)))}</h2>
                {/* <h2 className='mb-0 fs-14 fw-500'>₹{numberWithCommas(total?.totalSRP)}</h2> */}
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-500'>Orders Placed - Pending for delivery</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.pending_amount) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-500'>Inventory Account Balance</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.inventory_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-500'>Current Account Balance Payable</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.current_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-500'>Less : Online receipt account</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.online_receipt_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-500'>Less : Deposit Account Balance</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.deposit_account_balance) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-700'>Net Balance Payable to Specxweb</h2>
                <h2 className='mb-0 fs-14 fw-500'>₹{numberWithCommas(payableAmount(storeBillingDetail, total.totalLandingCost))}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
            <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
                <h2 className='mb-0 fs-14 fw-700'>Additional Credit Provided by SpecxWeb</h2>
                <h2 className='mb-0 fs-14 fw-400'>₹{numberWithCommas(parseFloat((Number(storeBillingDetail?.additional_credit) || 0).toFixed(2))) || 0}</h2>
            </div>
            <hr className="mt-3" style={{ border: '1px solid #22222260', }} />

            <Button
                className='mb-0 fs-18 fw-500 text-white border-1 w-100 mt-4 proceed-btn'
                isDisabled={(storeBillingDetail?.additional_credit < (payableAmount(storeBillingDetail, total.totalLandingCost)))}
                onClick={() => { checkout(productCartData) }}>Place Order</Button>
            {(Number(storeBillingDetail?.additional_credit) < payableAmount(storeBillingDetail, total.totalLandingCost)) && <p className="error-text text-center">{"Kindly clear your balance to place an order"}</p>}
        </div>
        // <div className="checkout-card">
        //     <h2 className='mb-0 fs-18 fw-600'>Billing Summary</h2>

        //     <div className="d-flex flex-wrap justify-content-between align-items-center mt-4">
        //         <h2 className='mb-0 fs-14 fw-500'>Total SRP</h2>
        //         <h2 className='mb-0 fs-14 fw-500'>₹{numberWithCommas(total?.totalSRP)}</h2>
        //     </div>
        //     <hr className="mt-3" style={{ border: '1px solid #22222260', }} />
        //     <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
        //         <h2 className='mb-0 fs-14 fw-500'>Total Landing Cost</h2>
        //         <h2 className='mb-0 fs-14 fw-500'>₹{numberWithCommas(total?.totalLandingCost)}</h2>
        //     </div>
        //     <hr className="mt-3" style={{ border: '1px solid #22222260', }} />

        //     <Button className='mb-0 fs-18 fw-500 text-white border-1 w-100 mt-4 proceed-btn'
        //         onClick={checkout}>Proceed To Checkout</Button>

        // </div>
    )
}

export default Index