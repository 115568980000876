import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { eCommerce } = Dictionary

const ECommerceService = {
    couponList: async (paramsObj: {}) => {
        return axios.get(BASE_URL + eCommerce.listCoupon(), { params: paramsObj })
    },
    couponCreate: async (payload:{}) => {
        return axios.post(BASE_URL + eCommerce.createCoupon(), payload )
    },
    couponUpdate: async (id:string,payload:{}) => {
        return axios.put(BASE_URL + eCommerce.updatedCoupon(id), payload )
    },
    couponDetail: async (id:string) => {
        return axios.get(BASE_URL + eCommerce.detailCoupon(id) )
    },
    couponDeleted: async (id:string) => {
        return axios.delete(BASE_URL + eCommerce.deleteCoupon(id) )
    },
    couponActivateDeactivate: async (id:string) => {
        return axios.put(BASE_URL + eCommerce.activeDeactivateCoupon(id) )
    },
}
export default ECommerceService