import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import atoms from "../../../../components/atoms";
import molecules from "../../../../components/molecules";
import constant from "../../../../constants/constant";
import { InventoryDetail, Inventory } from "../Addition/interface"
import { numberWithCommas } from "../../../../constants/utils"
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import service from "../../../../api/services";
import { useToasts } from 'react-toast-notifications';
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineFileDownload } from "react-icons/md";

const { Search, Button, Table, Image, Pagination } = atoms
const { LightBox } = molecules
const Index: React.FC<InventoryDetail> = ({ isRecognized, invoiceDetail, setLimitPerPage, setCurrentPage, setSearch, updateVerificationStatus,handleDownloadInventory,
    currentPage, limit }) => {
    const navigate = useNavigate()
    const inputRef: any = useRef()
    const { addToast } = useToasts();
    const [showSidebarModal, setShowSidebarModal] = useState(false)
    const [isViewData, setIsViewData] = useState<any>()
    const columns = [
        { name: 'Stock Addition ID', selector: (row: Inventory) => row.invoice_id_display, sortable: true },
        { name: 'Physical Store', selector: (row: Inventory) => row?.physical_store.store_name, },
        { name: 'Supplier Details', selector: (row: Inventory) => row?.local_supplier_name || row?.supplier?.supplier_name || '' },
        { name: 'Supplier Invoice No.', selector: (row: Inventory) => row.supplier_invoice_number },
        { name: 'Date of Addition', selector: (row: Inventory) => row?.createdAt ? moment(row?.createdAt)?.format('DD/MM/YYYY') : '-', sortable: true },
        { name: 'Supplier Invoice Date', selector: (row: Inventory) => moment(row.supplier_invoice_date).format('DD/MM/YYYY'), },
        { name: 'Invoice Value (₹)', selector: (row: Inventory) => numberWithCommas(parseFloat(row.total_amount?.toFixed(2))) },
        {
            name: 'Verification Status', selector: (row: any) => row.verification_status === constant.VERIFICATION_STATUS.PENDING ?
                (<div className="background-light-yellow border-product-radius"><p className="color-yellow m-1 p-1">{'Pending'}</p></div>) : row.verification_status === constant.VERIFICATION_STATUS.VERIFIED ?
                    (<div className="background-light-green border-product-radius"><p className="color-green m-1 p-1">{"Approved"}</p></div>) : row.verification_status === constant.VERIFICATION_STATUS.REWORK ?
                        (<div className="background-light-red border-product-radius"><p className="color-red m-1 p-1">{"Rework"}</p></div>) : '',
        },
        { name: 'Action', selector: (row: any) => row.action },
    ]

    const getViewData = async (id: string) => {
        try {
            let response = await service.inventory.getDetailById(id)
            setIsViewData(response.data.data)
            setShowSidebarModal(true)
        } catch (error: any) {
            console.log(error)
            addToast(error?.response?.data?.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }

    useEffect(() => {
        if (inputRef?.current) {
            inputRef.current.value = "";
        }
    }, [isRecognized]);
    return (
        <>
            <div className="row">
                <div className="col-6"><Search refs={inputRef} searchCallBack={(e: string) => { setSearch(e) }} /></div>
                <div className="col-6 text-end">
                    <Button name={"Add Inventory"}
                        className=''
                        onClick={() => navigate(`/inventory/add?isRecognized=${isRecognized}`)}
                    />
                </div>
            </div>
            <div >
                <Table columns={columns}
                    AdditionalClassName="add-invertory-table"
                    data={invoiceDetail?.data?.map((item: Inventory, index: number) => {
                        return ({
                            ...item,
                            action:
                                <>
                                    {/* <div className='table-data-width'>
                                        <button
                                            className="dropdown-item dropdown-item-size"
                                            onClick={() => {
                                                getViewData(item.id);
                                                setShowSidebarModal(true);
                                            }}
                                        >
                                            <div className='d-flex align-items-center'>
                                                <AiOutlineEye />
                                                <span className='ms-2 mb-0'>View</span>
                                            </div>
                                        </button>
                                    </div> */}
                                    {/* <div style={{ overflow: 'unset', textOverflow: 'unset' }}>
                                        <Button className="table-edit-buttons fs-14 fw-500"
                                            onClick={() => { getViewData(item.id)}}>
                                            <div className='d-flex align-items-center'>
                                                <AiOutlineEye className='fs-20' />
                                                <span className='ms-2 mb-0'>View</span>
                                            </div>
                                        </Button>
                                    </div> */}
                                    <div className='table-data-width'>
                                        <button
                                            className="btn dropdown-dots shadow-none action-button"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <Image src={ThreeDots} />
                                        </button>
                                        <ul
                                            className="dropdown-menu dropdown-menu-end dropdown-style"
                                            aria-labelledby="dropdownMenuButton1"
                                        >
                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => {
                                                        getViewData(item.id);
                                                        setShowSidebarModal(true);
                                                    }}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                        <AiOutlineEye />
                                                        <span className='ms-2 mb-0'>View</span>
                                                    </div>
                                                </button>
                                            </li>
                                            <li>
                                                <hr className="my-1"></hr>
                                            </li>
                                            <li>
                                                <button
                                                    className="dropdown-item dropdown-item-size"
                                                    onClick={() => handleDownloadInventory(item?.id)}
                                                >
                                                    <div className='d-flex align-items-center'>
                                                    <MdOutlineFileDownload />
                                                        <span className='ms-2 mb-0'>Download</span>
                                                    </div>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                </>

                        })
                    })}
                />
            </div>
            <div>
                <Pagination
                    totalCount={invoiceDetail?.count}
                    currentPage={currentPage}
                    totalPages={invoiceDetail?.totalPages}
                    onChangePage={(page: any) => setCurrentPage(page)}
                    setLimit={(limits: number) => setLimitPerPage(limits)}
                    limit={limit}
                />
            </div>
            {showSidebarModal && <div>
                <LightBox show={showSidebarModal} onHide={() => setShowSidebarModal(false)}
                    isViewData={isViewData}
                    updateVerificationStatus={(id: string, status: number) => {
                        updateVerificationStatus(id, status)
                    }}
                />
            </div>}
        </>
    )
}
export default Index