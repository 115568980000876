import React from "react";
import atoms from "../../../atoms";
import { CouponComponentProps } from "./interface";
import constant from "../../../../constants/constant";
import molecules from "../../../molecules";

const { Input, Select, Button } = atoms;
const { TopBar } = molecules

const Index = ({
    handleSubmit,
    handleChange,
    couponData,
    errors
}: CouponComponentProps) => {
    return (
        <div>
            <div className="col-6 mb-4">
                <TopBar heading={'Add'} />
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <Input
                        type="text"
                        label={"Coupon Name"}
                        value={couponData.coupon_name}
                        onChange={(e: any) => {
                            handleChange("coupon_name", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter Coupon Name"
                        errors={!(couponData.coupon_name) && errors?.code}
                    />
                </div>
                <div className="col-4">
                    <Input
                        type="text"
                        label={"Coupon Code"}
                        value={couponData.code}
                        onChange={(e: any) => {
                            handleChange("code", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter Coupon Code"
                        errors={!(couponData.code.length >= 5) && errors?.code}
                    />
                </div>
                <div className="col-4">
                    <Input
                        type="number"
                        label={"Discount Value"}
                        value={couponData.discount_value}
                        onChange={(e: any) => {
                            handleChange("discount_value", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter Discount Value"
                        errors={!couponData.discount_value && errors.discount_value}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                        Start Date
                    </label>
                    <input
                        type="date"
                        value={couponData.start_date}
                        onChange={(e: any) => {
                            handleChange("start_date", e.target.value);
                        }}
                        className="inputs form-control"
                        placeholder="Enter Start Date"
                        min={new Date().toISOString().split("T")[0]}
                    />
                    {!couponData.start_date && <span className="error-text">{errors.start_date}</span>}
                </div>
                <div className="col-4">
                    <label className="inputs-label mb-2 fs-16 fw-600  secondary-color">
                        End Date
                    </label>
                    <input
                        type="date"
                        value={couponData.end_date}
                        onChange={(e: any) => {
                            handleChange("end_date", e.target.value);
                        }}
                        min={new Date().toISOString().split("T")[0]}
                        className="inputs form-control"
                        placeholder="End Date"
                    />
                    {!couponData.end_date && <span className="error-text">{errors.end_date}</span>}
                </div>
                <div className="col-4">
                    <Input
                        type="number"
                        label={"Max Discount"}
                        value={couponData.max_discount}
                        onChange={(e: any) => {
                            handleChange("max_discount", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Max Discount"
                        errors={!couponData.max_discount && errors.max_discount}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <Select
                        label={"Coupon Type"}
                        placeholder={"Enter Coupon Discount Type"}
                        className={"w-100"}
                        options={constant.COUPON_TYPE}
                        value={couponData.discount_type.value && couponData.discount_type}
                        onChange={(e: any) => {
                            handleChange("discount_type", e);
                        }}
                        errors={!couponData.discount_type.value && errors.discount_type}
                    />
                </div>
                <div className="col-4">
                    <Select
                        label={"Coupon Sub Type"}
                        placeholder={"Enter Coupon Sub Type"}
                        className={"w-100"}
                        options={constant.COUPON_DISCOUNT_TYPE}
                        value={couponData.discount_sub_type.value && couponData.discount_sub_type}
                        onChange={(e: any) => {
                            handleChange("discount_sub_type", e);
                        }}
                        errors={!couponData.discount_sub_type.value && errors.discount_sub_type}
                    />
                </div>
                <div className="col-4">
                    <Input
                        type="number"
                        label={"Min Order Value"}
                        value={couponData.min_order_value}
                        onChange={(e: any) => {
                            handleChange("min_order_value", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter Min Order Value"
                        errors={!couponData.min_order_value && errors.min_order_value}
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-4">
                    <Input
                        type="number"
                        label={"Max Usage"}
                        value={couponData.max_usage}
                        onChange={(e: any) => {
                            handleChange("max_usage", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter Max Usage"
                        errors={!couponData.max_usage && errors.max_usage}
                    />
                </div>
                <div className="col-4">
                    <Input
                        type="text"
                        label={"Description"}
                        value={couponData.description}
                        onChange={(e: any) => {
                            handleChange("description", e.target.value);
                        }}
                        className="text-capitalize"
                        placeholder="Enter description"
                        errors={!couponData.description && errors.description}
                    />
                </div>
                <div className="col-4">
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <Button
                    name={"Submit"}
                    className={"w-25 m-4"}
                    onClick={() => {
                        handleSubmit();
                    }}
                />
            </div>
        </div>
    );
};

export default Index;
