import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import service from "../../../../api/services";
import organisms from "../../../../components/organisms";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";
import { HeaderHeading } from "../../../../Context";
import { useToasts } from "react-toast-notifications";
import molecules from "../../../../components/molecules";
import { useSearchParams } from "react-router-dom";

const { ECommerceCoupon } = organisms;
const { ConfirmationModal } = molecules;

const Index = () => {
  const { setHeader, setSidebar }: any = useContext(HeaderHeading);
  const [processListing, setProcessListing] = useState<any>();
  const [search, setSearch] = useState<string>("");
  const [itemId, setItemId] = useState<string>("");
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [isItemActive, setIsItemActive] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const isCouponActive = searchParams.get('isAcitve') === 'true'

  const { addToast } = useToasts();

  const getCouponListing = async () => {
    try {
      const response = await service.eCommerceService.couponList({
        search: search,
        is_active: isCouponActive,
      });
      if (response.status === 200) {
        setProcessListing(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activateCoupon = async () => {
    try {
      let response = await service.eCommerceService.couponActivateDeactivate(
        itemId
      );
      if (response.status === 200) {
        getCouponListing()
        setShowPopup(!showPopup)
      }
    } catch (error:any) {
      console.error(error);
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  };
  const deleteCoupon = async () => {
    try {
      let response = await service.eCommerceService.couponDeleted(itemId);
      if (response.status === 200) {
        getCouponListing()
      }
    } catch (error:any) {
      console.error(error);
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
    setShowPopup(!showPopup)
  };


  useEffect(() => {
    getCouponListing();
  }, [search,isCouponActive]);

  useEffect(() => {
    setHeader("E-commerce- Coupon");
    setSidebar(false);
  }, [ ]);

  return (
    <Wrapper>
      <ECommerceCoupon
        processListing={processListing}
        setSearch={setSearch}
        setItemId={(id: string) => setItemId(id)}
        setIsDeletePopup={(value: boolean) => setIsDeletePopup(value)}
        setShowPopup={(value: boolean) => setShowPopup(value)}
        setIsItemActive={(value: boolean) => setIsItemActive(value)}
        isCouponActive={isCouponActive}
      />
      {showPopup && <ConfirmationModal
        show={showPopup}
        handleConfirmItem={() => isDeletePopup ? deleteCoupon() : activateCoupon()}
        onHide={() => setShowPopup(!showPopup)}
        message={isDeletePopup ? "delete this coupon" : ` ${isItemActive ? "activate" : "deactivate"} this coupon`}
      />}
    </Wrapper>
  );
};

export default Index;
