import React, { useState, useEffect, useContext } from 'react';
import service from '../../../../api/services';
import Wrapper from '../../../../components/common/Wrapper/Wrapper';
import organisms from '../../../../components/organisms';
import { Default_Page_Limit, Default_Page_Number } from '../../../../constants/defaultValues';
import constant from '../../../../constants/constant';
import { InvoiceData } from '../Addition/interface'
import { HeaderHeading } from '../../../../Context'
import { useSearchParams } from 'react-router-dom';
import { getParams, handleInventoryDownload } from '../../../../constants/utils';
import { useToasts } from 'react-toast-notifications';
const { ApproveInventory } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const [searchParams] = useSearchParams();
    const [typeValue, setTypeValue] = useState<any>(getParams('approve-list-type'))
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [invoiceDetail, setInvoiceDetail] = useState<InvoiceData | any>({});
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [search, setSearch] = useState('');
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    
    
    useEffect(() => {
        setTypeValue(getParams('approve-list-type'))
        setInvoiceDetail({})
        setCurrentPage(Default_Page_Number)
        setLimitPerPage(Default_Page_Limit)
        setSearch('')
    }, [searchParams])
    
    const getInvoiceData = async () => {
        try {
            let response = await service.inventory.view({ is_recognised: typeValue == constant.BRAND_CATEGORY[0].value , "page": currentPage, "search": search, "limit": limitPerPage, "status": constant.VERIFICATION_STATUS.VERIFIED })
            if (response.status === 200) {
                setInvoiceDetail(response.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleUpdateStatus = async (id: any, status: any) => {
        try {
            let response = await service.inventory.updateStatusById(id, { "status": status });
            getInvoiceData()
        } catch (error) {
            console.log(error)
        }
    }

    const handleDownloadInventory = async (id: string) => {
        try {
          let response = await service.inventory.getDetailById(id)
          if (response.status == 200) {
            if(response?.data?.data?.attachments?.length>0){
              for(let i=0; i< response?.data?.data?.attachments?.length ; i++ ){
                handleInventoryDownload(response?.data?.data?.attachments[i], "inventory_attachment");
              }
            }else{
              addToast("There is no attachment in inventory", {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
              });
            }
          }
        } catch (error) {
          console.error(error)
        }
      }


    useEffect(() => {
        setHeader("Inventory - Approved")
        setSidebar(false)
        getInvoiceData()
    }, [currentPage, searchParams])

    useEffect(() => {
        setCurrentPage(1)
        if (currentPage == 1 && initialLoading) {
            getInvoiceData()
        }
        setInitialLoading(true)
    }, [limitPerPage, search,typeValue])

   
    return (
        <Wrapper>
            <ApproveInventory
                invoiceDetail={invoiceDetail}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                updateVerificationStatus={(id: string, status: any) => handleUpdateStatus(id, status)}
                setSearch={setSearch}
                currentPage={currentPage}
                limit={limitPerPage}
                isRecognized={typeValue == constant.BRAND_CATEGORY[0]?.value}
                handleDownloadInventory={(id:string)=>handleDownloadInventory(id)}
            />
        </Wrapper>
    )
}
export default Index