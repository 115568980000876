import React, { useState } from 'react'
import { InputInterface } from './interface'
import Button from '../Button'
import './Input.scss'
import moment from 'moment'
const Input = ({ id, type, name, disabled, defaultValue,
    onChange, errors, className, placeholder, label,
    htmlFor, accept, multiple, value, checked, required,
    ref, maxLength, onWheel, min, max, onClick, allowArthmetics = false, ...restProp }: InputInterface) => {
    const handleKeyDown = (event: any) => {
        if (type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'e' || event.key === '+' || event.key === '-')) {
            event?.preventDefault();
        }
    }
    const handleAllowArthmetics = (event: any) => {
        if (type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'e')) {
            event?.preventDefault();
        }
    }
    function getCurrentDate() {
        const today = new Date();
        const year = today?.getFullYear();
        let month: number | string = today.getMonth() + 1;
        let day: number | string = today.getDate();

        // Add leading zeros if necessary
        month = (month < 10) ? `0${month}` : month;
        day = (day < 10) ? `0${day}` : day;

        return `${year}-${month}-${day}`;
    }
    // function handleDataChange(event: any) {
    //     const selected = event?.target?.value;
    //     if (type === 'date' && selected <= getCurrentDate()) {
    //         onChange(selected);
    //     } else {
    //         onChange(event)
    //     }
    // }
    function handleDataChange(event: any) {
    let selected = event?.target?.value;

    if (type === 'date' && selected <= getCurrentDate()) {
        // Handle date type inputs
        onChange(selected);
    } else {
        if(allowArthmetics){
            const sanitizedValue = selected.replace(/[^0-9+\-\.]/g, "");
            onChange(sanitizedValue);
        }else{
            onChange(event);
        }
    }
}

    const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
        if (type === 'number') {
            (event.target as HTMLInputElement)?.blur();
        }
    };
    return (
        <div className="form-group">
            {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
            <input
                id={id}
                className={`${type === "radio" ? '' : "inputs form-control "} shadow-none ${className}`}
                type={type}
                name={name}
                defaultValue={defaultValue}
                onChange={handleDataChange}
                onClick={onClick}
                onWheel={handleWheel}
                placeholder={placeholder}
                accept={accept}
                multiple={multiple}
                value={value}
                checked={checked}
                required={required}
                ref={ref}
                onKeyDown={allowArthmetics ? handleAllowArthmetics : handleKeyDown}
                disabled={disabled}
                maxLength={maxLength}
                max={`${type === "date" ? moment().format("YYYY-MM-DD") : max}`}
                min={min}
                {...restProp}
            />
            {errors && <span className="error-text">{errors}</span>}
        </div>
    )
}

export default Input