import React from "react";
import atoms from "../../../atoms";
import { useNavigate } from "react-router-dom";
import { findConstantLabel } from "../../../../constants/utils";
import constant from "../../../../constants/constant";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import ThreeDots from "../../../../assets/Images/threeDots.svg";
import { FiEdit } from "react-icons/fi";
import IsActiveFilter from "./filter"
import { CouponProps } from "./interface";

const { Search, Button, Table, Image } =
  atoms;

const Index = ({
  setSearch,
  processListing,
  setItemId,
  setIsDeletePopup,
  setShowPopup,
  setIsItemActive,
  isCouponActive,
  // isActive,
  // setIsActive
}: CouponProps) => {
  const navigate = useNavigate();

  const columns = [
    {
      name: "Coupon Name",
      selector: (row: any) => row?.name || "",
      minWidth: "150px",
    },
    {
      name: "Code",
      selector: (row: any) => row?.code || "",
    },
    {
      name: "Coupon Type",
      selector: (row: any) => findConstantLabel(constant.COUPON_TYPE, row?.coupon_type),
      minWidth: "110px",
    },
    {
      name: "Description",
      selector: (row: any) => row?.description,
      minWidth: "150px",
    },
    {
      name: "Discount Type",
      selector: (row: any) => findConstantLabel(constant.COUPON_DISCOUNT_TYPE, row?.discount_type),
      minWidth: "115px",
      center: true,
    },
    {
      name: "Discount Value",
      selector: (row: any) => row.discount_value || "-",
      minWidth: "115px",
    },
    // {
    //   name: "Created Time",
    //   selector: (row: any) =>
    //     `${row?.createdAt
    //       ? moment(row?.updatedAt).format("DD/MM/YYYY ")
    //       : "--"
    //     }`,
    //   sortable: true,
    // },
    {
      name: "start_date",
      selector: (row: any) =>
        moment(row?.start_date).format("DD/MM/YYYY ") || "-",
    },
    {
      name: "Expire date",
      selector: (row: any) =>
        moment(row?.end_date).format("DD/MM/YYYY") || "-",
    },
    {
      name: "Verification Status",
      selector: (row: any) => row.verification_status,
    },
    { name: "Action", selector: (row: any) => row.action },
  ];

  return (
    <div>
      <div className="row align-items-center">
        <div className="col-6">
          <Search
            searchCallBack={(e: string) => {
              setSearch(e);
            }}
          />
        </div>
        <div className="col-6 d-flex justify-content-end align-items-center">
          <div>
            <IsActiveFilter
              value={isCouponActive}
              onStatusChange={(value: boolean) => navigate(`/e-commerce/coupon?isAcitve=${value}`) }
            />
          </div>
          <div>
            <Button
              name={"Add Coupon"}
              className=""
              onClick={() => navigate(`/e-commerce/coupon/add`)}
            />
          </div>
        </div>
      </div>
      <div>
        <Table
          AdditionalClassName="add-invertory-table"
          className="inventory-product-border"
          columns={columns}
          data={processListing?.data?.map((item: any) => {
            return {
              ...item,
              verification_status: item.is_active ? (
                <div className="background-light-green border-product-radius">
                  <p className="color-green m-1 p-1">{"Active"}</p>
                </div>
              ) : (
                <div className="background-light-red border-product-radius">
                  <p className="color-red m-1 p-1">{"Deactivate"}</p>
                </div>
              ),
              action: (
                <>
                  {" "}
                  <div className="table-data-width">
                    <button
                      className="btn dropdown-dots shadow-none action-button"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Image src={ThreeDots} />
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-end dropdown-style"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      {
                        (
                          <>
                            <li>
                              <button
                                className="dropdown-item dropdown-item-size"
                                onClick={() => { setItemId(item.id); setIsDeletePopup(false); setShowPopup(true); setIsItemActive(!item.is_active) }}
                              >
                                <div className="d-flex align-items-center">
                                  <FiEdit />
                                  <span className="ms-2 mb-0">{!item.is_active ? "Active" : "Deactivate"}</span>
                                </div>
                              </button>
                              {/* )} */}
                            </li>
                          </>
                        )}

                      {/* ) : ( */}
                      <li>
                        <hr className="my-1"></hr>
                      </li>
                      <li>
                        <button
                          className="dropdown-item dropdown-item-size"
                          onClick={() => { setItemId(item.id); setIsDeletePopup(true); setShowPopup(true) }}
                        >
                          <div className="d-flex align-items-center text-danger">
                            <RiDeleteBin6Line />
                            <span className="ms-2 mb-0">Delete</span>
                          </div>
                        </button>
                        {/* )} */}
                      </li>
                    </ul>
                  </div>
                </>
              ),
            };
          })}
        />
      </div>
    </div>
  );
};

export default Index;
