import React, { useEffect, useRef } from 'react';
import { InputInterface } from './interface';
import './Input.scss'

const FileInput: React.FC<InputInterface> = ({ label, id, onChange,multiple,accept, className,errors,htmlFor,fileRef, ...restProp }) => {
    // const fileRef:any= useRef()

    return (
        <div>
           {label && <label className='inputs-label mb-2 fs-16 fw-600  secondary-color' htmlFor={htmlFor}>{label}</label>}
            <input
            style={{minHeight:'55px'}}
                className={`form-control form-control-lg ${className}`}
                id={id}
                type="file"
                onChange={(e)=>{onChange(e);console.log("fileRef",fileRef)}}
                multiple={multiple}
                ref={fileRef}
                accept={accept}
            />
            {errors && <span className="error-text">{errors}</span>}
        </div>
    );
};

export default FileInput;
