import React, { useContext, useEffect, useState } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { HeaderHeading } from '../../../../Context'
import constant from "../../../../constants/constant";
import { Contact_lens, Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues"
import { BASE_URL } from "../../../../constants/defaultValues"
import { getContactLensType, getParams, modifiedDataForContactLens } from "../../../../constants/utils";
import { useSearchParams } from "react-router-dom";

const { ReworkProduct } = organisms
const Index = () => {
    const [searchParams] = useSearchParams();
    const [initialLoading, setInitialLoading] = useState<boolean>(false)
    const [search, setSearch] = useState('');
    const [productData, setProductData] = useState<any>({})
    const [productDetail, setProductDetail] = useState({});
    const [currentPage, setCurrentPage] = useState(Default_Page_Number);
    const [limitPerPage, setLimitPerPage] = useState(Default_Page_Limit);
    const [brand, setBrand] = useState<any>([])
    const [productType, setProductType] = useState<any>([])
    const [newProductData, setNewProductData] = useState<any>({})
    const [typeValue, setTypeValue] = useState<any>(getParams('rework-type'))
    const [isContactLensProduct, setIsContactLensProduct] = useState<boolean>(getContactLensType())

    // const [isLoading, setIsLoading] = useState(false)
    const { setHeader, setSidebar }: any = useContext(HeaderHeading);
    const handleDownload = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        const payload = {
            product_types: product_types,
            modal_number: newProductData?.modal_number,
            brands: brands,
            status: constant.VERIFICATION_STATUS.REWORK,
            is_contact_lens: isContactLensProduct,
            brand_category: typeValue,
        }
        try {
            const response = await service.BulkEditService.download(payload)
            if (response.status === 200) {
                let path = response.data.data
                let fullPath = `${BASE_URL}${path}`
                let cleanPAth = fullPath.replace(/api\/\.\//, '')
                let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)

                setTimeout(() => {
                    fetch(`${cleanPAth}`).then((response) => {
                        response.blob().then((blob) => {

                            // Creating new object of PDF file
                            const fileURL =
                                window.URL.createObjectURL(blob);

                            // Setting various property values
                            let alink = document.createElement("a");
                            alink.href = fileURL;
                            alink.download = `${fileName}`;
                            alink.click();
                        });
                    });
                }, 2000);

            }
        } catch (error) {
            console.log(error)

        }
    }
    useEffect(() => {
        setTypeValue(getParams('rework-type'))
        setProductData({})
        setBrand([])
        setProductType([])
        setCurrentPage(Default_Page_Number)
        setLimitPerPage(Default_Page_Limit)
        setSearch('')
    }, [searchParams])
    const getProductData = async () => {
        try {
            let brandResponse = await service.productData.brandListingService({ brand_category: typeValue })
            let productTypeResponse = await service.productData.productTypeListingService({ search: '' });
            setBrand(getDropdown(brandResponse?.data?.data))
            if (productTypeResponse.status === 200) {
                let modifiedData: any = modifiedDataForContactLens({ data: productTypeResponse.data.data, filterCondition: (item:any) => (item?.name)?.toLowerCase() !== Contact_lens })
                setProductType(getDropdown(modifiedData))
            }
        } catch (error) {
            console.error(error)
        }
    }

    const getDropdown = (response: []) => {
        let dropdown: any = []
        response && response.map((value: any) => {
            dropdown.push({
                "value": value.id || value.product_type.id,
                "label": value.name || value.brand_name || value.color_name || value.product_type.name,
                "code": value?.brand_code || value?.product_type_initials || value?.product_type?.product_type_initials
            }
            )
        })
        return dropdown
    }



    const getAllDetail = async () => {
        const brands = newProductData?.brand_id?.map((item: any) => item?.value)
        const product_types = newProductData?.product_type_id?.map((item: any) => item?.value)
        let NewBrands = JSON.stringify(brands)
        let NewProductTypes = JSON.stringify(product_types)


        try {
            let response = await service.product.view(
                {
                    "page": currentPage,
                    "search": search,
                    "limit": limitPerPage,
                    "status": constant.VERIFICATION_STATUS.REWORK,
                    "brands": NewBrands,
                    "product_types": NewProductTypes,
                    "modal_number": newProductData?.modal_number,
                    "brand_category": typeValue,
                    "is_contact_lens": isContactLensProduct
                })
            if (response.status === 200) {
                setProductData(response?.data)
                localStorage.removeItem('isContactLensProduct')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getDetailById = async (id: string) => {
        try {
            let response = await service.product.getDetailById(id)
            if (response.status === 200)
                setProductDetail(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const updateVerificationStatus = async (id: string, status: number) => {
        try {
            await service.product.updateStatusById(id, { "status": status });
            getAllDetail()
        } catch (error) {
            console.log(error)
        }
    }

    const handleProductDelete = async (id: string) => {
        try {
            let response = await service.product.productDelete(id);
            if (response.status === 200) {
                getAllDetail()
            }
        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        getProductData()
    }, [typeValue])

    useEffect(() => {
        setHeader("Product - Rework")
        setSidebar(false)
        getAllDetail()
    }, [currentPage, newProductData, typeValue, limitPerPage, search,isContactLensProduct])


    return (
        <Wrapper>
            <ReworkProduct
                productResponse={productData}
                productDetail={productDetail && productDetail}
                updateVerificationStatus={(id: string, status: number) => { updateVerificationStatus(id, status) }}
                getDetailById={(id: string) => { getDetailById(id) }}
                setLimitPerPage={setLimitPerPage}
                setCurrentPage={setCurrentPage}
                setSearch={setSearch} currentPage={currentPage} limit={limitPerPage}
                handleProductDelete={handleProductDelete}
                brand={brand}
                productType={productType}
                setProductData={setNewProductData}
                handleDownload={handleDownload}
                isContactLensProduct={isContactLensProduct}
                setIsContactLensProduct={(value: boolean) => setIsContactLensProduct(value)}
                newProductData={newProductData}
            />

        </Wrapper>
    )
}
export default Index