import axios from "axios"
import { BASE_URL } from "../../constants/defaultValues"
import dictionary from "../dictionary"

const { permission } = dictionary

const subAdminService = {
    createPermission: async (data: {}) => {
        return axios.post(BASE_URL + permission.permission(), data)
    },
    getPermissionService: async () => {
        return axios.get(BASE_URL + permission.permission())
    },
    deletePermission: async (id: string) => {
        return axios.delete(BASE_URL + permission.permissionById(id))
    },
    structuredPermission: async () => {
        return axios.get(BASE_URL + permission.structuredPermission())
    },

    // for update the permission
    getPermissionById:async (id:string)=>{
        return axios.get(BASE_URL + permission.permissionById(id))
    },
    updatePermission:async(payload:{}, id:string)=>{
        return axios.put(BASE_URL + permission.permissionById(id),payload)
    }
}
export default subAdminService