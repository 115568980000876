import { useContext } from "react"
import { PermissionData } from "../Context"
import * as XLSX from "xlsx"
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import moment from "moment";
import { BASE_URL } from "./defaultValues";
import constant from "./constant";
import { ModifiedDataForContactLensProps ,FileChangeHandlerProps} from "./interface";

// import { useToasts } from 'react-toast-notifications';


export const getDropdownValue = (constant: any, ind: any) => {
    let temp: any = {
        "value": 0,
        "label": '',
    }
    constant.map((item: any) => {
        if (item.value === ind) {
            temp.value = item.value
            temp.label = item.label
            temp.code = item.code
        }
    })
    return temp
}
export const getUniqueObj = (data: any, id: string, compareId: string) => {
    let tempObj: any
    data && data.map((item: any) => {
        if (item[id] === compareId) {
            tempObj = item
        }
    })
    return tempObj
}
export const getDropdown = (response: [], name: string, additionName?:string,anotherKey?:any) => {
    let dropdown: any = []
    response && response.map((value: any) => {
        dropdown.push({
            "value": value.id,
            "label": value[name] === null || undefined ?  value[additionName ? additionName : name] : value[name],
            ...(anotherKey ? { [anotherKey]: value[anotherKey] } : {})
        }
        )
    })
    return dropdown
}

export const handleNumber = (e: any) => {
    const inputValue = e.target.value;
    const digitsOnly = inputValue.replace(/\D/g, '');
    if (digitsOnly.length <= 10) {
        return digitsOnly
    }
}
export const handleRemoveObject = (data: any, index: number) => {
    data?.splice(index, 1)
    return data
}

export const checkPermissionsInArray = (code: string) => {
    let perm: any;
    const [userPermission, setUserPermission] = useContext<string[]>(PermissionData);
    if (userPermission == undefined) {
        perm = localStorage.getItem("permissions")
    }
    let permString = userPermission || perm

    const result = [];
    for (let i = 0; i < permString?.length; i += 5) {
        result.push(permString.slice(i, i + 5));
    }

    return result?.includes(code)

};

export const emailValidation = (email: string) => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/
    let error
    if (email) {
        error = emailRegex.test(email)
    } else {
        error = true
    }
    return error
};

export const findConstantLabel = (constant: { label: string, value: string | number }[], value: string | number) => {
    const cosntVal: any = constant.find((item: any) => item.value === value);
    return cosntVal ? cosntVal.label : 'Unknown';
}

export const numberWithCommas = (number: number) => {
    return number.toLocaleString('en-IN'); // Use 'en-IN' for the Indian numbering system
};

export const setMaxNumber = (val: string): number => {
    const max = 100;
    const inputRegex = /^(100|\d{1,2})$/;

    // Convert the number to a string for regex matching
    const newValue = val.toString();

    // Check if the input matches the regex pattern
    if (inputRegex.test(newValue) || newValue === '') {
        // Return the limited number if it matches the pattern or is an empty string
        return parseInt(newValue, 10);
    } else {
        // Return the maximum allowed number if the input does not match the pattern
        return max;
    }
};

export const convertJsonToExcel = (jsonData: any, filename: any) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const getGstTypeName = (constant: any, value: string | number) => {
    return Object.keys(constant).find(key => constant[key] === value);
};

// download file from api
export const handleReportDownload = async (response: any, name: string, report_format: number, report_heading: string, date_section?: boolean,) => {
    let path = response
    let fullPath = `${BASE_URL}${path}`
    let cleanPAth = fullPath.replace(/api\/\.\//, '')
    let fileName = cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)
    // window.open(`${cleanPAth}`, '_blank')

    if (report_format === constant?.DailyReportFormat[0].value) {

        // convert the data to json
        const xlsResponse = await fetch(cleanPAth);
        const xlsData = await xlsResponse.arrayBuffer();

        // Convert XLS data to JSON
        const workbook = XLSX.read(new Uint8Array(xlsData), { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        generatePdfHeader(jsonData, 4, name, report_heading, date_section)
    }

    if (report_format === constant?.DailyReportFormat[1].value) {

        setTimeout(() => {
            fetch(`${cleanPAth}`).then((response) => {
                response.blob().then((blob) => {

                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${fileName}`;
                    alink.click();
                });
            });
        }, 2000);
    }
}

// for generate pdf

const addTotalLine = (data: any, pdfName: string) => {
    if (pdfName === 'inventory-product-wise-report') {
        let totalSRP = data[data?.length - 1]["S.No."]
        let totalBrandLandingCost = data[data?.length - 1]["Product Id"]

        data.pop()
        const newObject = {
            "id": "",
            "S.No.": "",
            "Product Id": "",
            "Product Type": "",
            "Brand": "",
            "Model No.": "",
            "Brand Color Code": "",
            "Glass Size/Power": "",
            "Polarised": "",
            "Product With Case": "",
            "Warrantee": "",
            "SRP": totalSRP,
            "Brand Landing cost": totalBrandLandingCost,
            "Total Quantity": "",
            "Total Impaired Quantity": ""
        };

        // Add the new object to the end of the array
        data.push(newObject);

    }
    return data
}

export const generatePdfHeader = (data: any, countOfRowHaveData: number, pdfName: string, report_heading: string, date_section?: boolean,) => {
    try {
        if (!data || data.length < countOfRowHaveData) {
            throw new Error("Invalid input data or insufficient rows.");
        }

        const headerRow = data[countOfRowHaveData - 1]; // Get the fifth-to-last row
        const headerValues = Object.values(headerRow);
        generatePdfData(data, headerValues, countOfRowHaveData, pdfName, report_heading, date_section)
    } catch (error) {
        console.log("Error in generatePdfHeader", error)
    }
}

const getValueFromString = (valueArr: any, name: string) => {
    try {
        if (!valueArr) {
            throw new Error("Value array is null or undefined.");
        }
        let lastIndex = valueArr.lastIndexOf([name]); // last occurrence of space
        if (lastIndex < 0) {
            return valueArr?.split(name)[1]
        }
        let value = valueArr?.substring(lastIndex + 1);
        return value
    } catch (error) {
        console.log("Error in getValueFromString", error)
    }
}

const generatePdfData = (data: any, header: any, countOfRowHaveData: number, pdfName: string, report_heading: string, date_section?: boolean) => {
    try {
        if (!data) {
            throw new Error("Input data is null or undefined.");
        }

        const pdfData = data.slice(countOfRowHaveData).map((row: any, index: number) => {
            const headerValues = Object.values(row);
            const obj: any = { id: index + 1 };
            header.forEach((key: any, j: number) => {
                obj[key] = headerValues[j];
            });
            return obj;
        });

        // (0,1,2) are the row in which storeName , date in the xlsx last 0 add to access the data form array
        // ":","n","m", is take to separate the string it take from xlsx 
        pdfData.reportHeading = report_heading ? report_heading : ''
        pdfData.storeName = getValueFromString(Object.values(data[0])[0], ":")
        pdfData.reportGeneratedOnTime = (getValueFromString(Object.values(data[1])[0], "n").split(" ")[2]);
        pdfData.reportGeneratedOnDate = (getValueFromString(Object.values(data[1])[0], "n").split(" ")[1]);
        pdfData.reportIsFrom = date_section ? getValueFromString(Object.values(data[2])[0], "m") : getValueFromString(Object.values(data[2])[0], " ")
        // pdfData.reportIsFrom = date_section ? getValueFromString(Object.values(data[2])[0], "m") : getValueFromString(Object.values(data[2])[0], " ")

        generatePDF(header, addTotalLine(pdfData, pdfName), pdfName, date_section);

    } catch (error) {
        console.log("Error in generatePdfData", error)
    }
};

const createHeaders = (keys: string[]) => {
    try {
        const result = keys.map(key => {
            return {
                id: key,
                name: key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1').replace(/_/g, ' ').trim(),
                prompt: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
                width: 65,
                align: "center",
                padding: 0
            };
        });
        return result;
    } catch (error) {
        console.log("Error in createHeaders")
    }
};

const generatePDF = (header: any[], pdfData: any, pdfName: string, date_section?: boolean) => {
    try {
        if (!pdfData || !Array.isArray(header) || header.length === 0) {
            throw new Error("Invalid input data for PDF generation.");
        }
        const headers: any = createHeaders(header);

        const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: "landscape", format: 'a4', });
        const data = pdfData?.map((entry: any) => ({
            ...entry,
            id: entry.id.toString() // Convert id to string explicitly
        }));

        // Add heading
        if (pdfData?.reportHeading) {
            const heading = `${pdfData?.reportHeading}`
            doc.setFontSize(20);
            let textWidth = doc.getStringUnitWidth(heading) * doc.getFontSize() / doc.internal.scaleFactor;
            let xCoordinate = parseFloat(((doc.internal.pageSize.width - textWidth) / 2).toFixed(2));
            doc.text(heading, xCoordinate, 10);
        }

        // Add date

        if (pdfData?.storeName) {
            const report_date_to = `${pdfData?.storeName}`;
            doc.setFontSize(10);
            doc.text(`Report Heading`, 15, 15);
            doc.setTextColor(80, 90, 111);
            doc.text(`${report_date_to}`, 41, 15);
        }

        if (pdfData?.reportGeneratedOnDate) {
            const report_date_to = `${pdfData?.reportGeneratedOnDate}, ${pdfData?.reportGeneratedOnTime}`;
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(`Report Generated on`, 15, 20);
            doc.setTextColor(80, 90, 111);
            doc.text(`${report_date_to}`, 50, 20);
        }

        if (pdfData?.reportIsFrom) {
            const report_date_from = ` ${pdfData?.reportIsFrom}`;
            doc.setFontSize(10);
            doc.setTextColor(0, 0, 0);
            doc.text(`${date_section ? "Report is from" : "Date of the report"}`, 15, 25);
            doc.setTextColor(80, 90, 111);
            doc.text(`${report_date_from}`, date_section ? 38 : 43, 25);
        }

        (doc as any).autoTable({
            head: [headers.map((header: any) => header.prompt)],
            body: data.map((row: any) => headers.map((header: any) => row[header.id])),
            startY: 30,// Adjust starting Y position as needed,
        });
        doc.save(`${pdfName}.pdf`);
    } catch (error) {
        console.error("Error in generatePDF", generatePDF)
    }
};

// generate pdf code end here

export const convertArrayToKeyValue = (array: []) => {
    return array.map(size => ({
        label: size,
        value: size
    }));
}

export const isActiveQuery = (queryKey: any, queryValue: any) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams?.get(queryKey) == queryValue;
};
export const getParams = (path: string) => {
    let urlParams = new URLSearchParams(location.search);
    let typeValue:any = urlParams?.get(path)
    return typeValue
}


export const modifiedDataForContactLens = ({ data, filterCondition }: ModifiedDataForContactLensProps) => {
    return  data?.filter(filterCondition) || []
}


export const getContactLensType = () => {
    const storedValue:any = localStorage.getItem('isContactLensProduct') === 'true'
    if (storedValue) {
        try {
            return JSON.parse(storedValue);
        } catch (error) {
            console.error('Error parsing localStorage value:', error);
            return false; 
        }
    }
    return false;
}

export const handleFilesToBase64 = ({
    event,
    setData,
    dataKey,
    errors,
    setErrors,
  }: FileChangeHandlerProps) => {
    const files = event?.target?.files;
    const fileArray = Array.from(files || []);

    if (!fileArray.length) return;
  
    const reader = new FileReader();
    let fileCount = 0;
    const base64Files: string[] = [];
  
    const processFile = (file: File) => {
      reader.onloadend = () => {
        const base64String = reader.result as string;
  
        // Ensure the base64String is a string before using match or other methods
        if (typeof base64String === "string") {
          // Optional: Example of matching a specific pattern (e.g., base64 image)
          const isValidBase64 = base64String.match(/^data:image\/(png|jpg|jpeg|pdf|svg);base64,/);
  
          if (isValidBase64) {
            base64Files.push(base64String); // Store the Base64 result
          } else {
            console.error("Invalid Base64 format:", base64String); // Handle invalid Base64 formats
          }
        }
  
        fileCount++;
        // console.log("fileArray>>>>>>>>>>>>",fileArray,base64Files)
        // If all files are processed, update the state
        if (fileCount === fileArray.length) {
          setData((prevData: any) => ({
            ...prevData,
            [dataKey]: base64Files, // Store Base64 array in the state
          }));
  
          // Clear any error related to the file input
          setErrors({ ...errors, [dataKey]: "" });
        }
      };
  
      reader.readAsDataURL(file); // Start reading the file
    };
  
    // Process each file in the array
    fileArray.forEach(processFile);
  };

  export const downloadBase64File = (base64String: string, fileName: string = 'downloaded_file') => {
    // Extract MIME type from the Base64 string
    const mimeMatch = base64String?.match(/^data:(.*?);base64,/);
    const mimeType = mimeMatch ? mimeMatch[1] : '';
  
    // Map MIME types to file extensions
    const mimeToExtension: { [key: string]: string } = {
      'application/pdf': 'pdf',
      'image/png': 'png',
      'image/jpeg': 'jpg',
      'image/jpg': 'jpg',
      'text/plain': 'txt',
    };
  
    const fileExtension = mimeToExtension[mimeType] || 'bin'; // Default to 'bin' for unknown types
  
    // Remove the Base64 prefix and decode to a Blob
    const base64Data = base64String.split(',')[1];
    const blob = base64ToBlob(base64Data, mimeType);
  
    // Create a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.${fileExtension}`; // Add the detected extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  // Helper function to convert Base64 to Blob
  const base64ToBlob = (base64Data: string, mimeType: string) => {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
  
    return new Blob(byteArrays, { type: mimeType });
  };

  export const handleDownloadUrlFile = async (fileUrl:string) => {
    try {
      const response = await fetch(fileUrl); 
      const blob = await response.blob(); 
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'editInvoice.pdf'; 
      link.click();
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  export const handleInventoryDownload = async (response: any, name: string,) => {
    let path = response
    let fullPath = `${path}`
    let cleanPAth = fullPath.replace(/api\/\.\//, '')
    let fileName = name ? `${name}${cleanPAth.lastIndexOf("/") + 1}` : cleanPAth.substring(cleanPAth.lastIndexOf("/") + 1)

        setTimeout(() => {
            fetch(`${cleanPAth}`).then((response) => {
                response.blob().then((blob) => {

                    // Creating new object of PDF file
                    const fileURL = window.URL.createObjectURL(blob);

                    // Setting various property values
                    let alink = document.createElement("a");
                    alink.href = fileURL;
                    alink.download = `${fileName}`;
                    alink.click();
                });
            });
        }, 2000);
}

export const convertToCommaFormat = (value: string | number): string => {
    return numberWithCommas(Number(Number(value)?.toFixed(2))) || ''
}
  