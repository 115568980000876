import React from "react";

interface IndexProps {
  value: boolean;
  onStatusChange: (value:boolean) => void;
}

const Index = ({ value, onStatusChange }: IndexProps) => {
  return (
    <div className="d-flex gap-3">
      <div
        className={`form-check form-check-inline mb-1 w-100 ${
          value ? "fw-600" : "fw-400 text-muted"
        }`}
        style={{
          display: "block",
          cursor: "pointer",
        }}
      >
        <label
          className="form-check-label w-100"
          htmlFor={`status-1`}
          style={{ cursor: "pointer" }}
          onClick={()=>onStatusChange(true)}
        >
          <input
            className={`form-check-input`}
            type="radio"
            name={`status-1`}
            id={`status-`}
            value={value.toString()}
            checked={value}
            // disabled={value}
            style={{ fontWeight: "bold" }}
          />
          Active
        </label>
      </div>
      <div
        className={`form-check form-check-inline mb-1 w-100 ${
          !value ? "fw-600" : "fw-400 text-muted"
        }`}
        style={{
          display: "block",
          cursor: "pointer",
        }}
      >
        <label
          className="form-check-label w-100"
          htmlFor={`status-1`}
          style={{ cursor: "pointer", minWidth: "120px" }}
          onClick={()=>onStatusChange(false)}
        >
          <input
            className={`form-check-input`}
            type="radio"
            name={`status-2`}
            id={`status-`}
            value={value.toString()}
            checked={!value}
            // disabled={value}
            style={{ fontWeight: "bold" }}
          />
          Deactivate
        </label>
      </div>
    </div>
  );
};

export default Index;
