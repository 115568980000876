// hooks/useNotification.ts
import { useToasts } from 'react-toast-notifications';

const useNotification = () => {
  const { addToast } = useToasts();

  const notify = (message: string, options = {}) => {
    addToast(message, options);
  };

  return { notify };
};

export default useNotification;
