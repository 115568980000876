const Product = {
    productSearch: ()=> `inventory/invoice/products`,
    product: () => 'admin/product',
    productById: (id: string) => `admin/product/${id}`,
    deleteProductImage: () => `admin/product/images`,
    verificationStatus: (id: string) => `admin/product/${id}/verification-status`,
    productImages: () => `admin/product/images`,
    bulkUpload:()=>'admin/product/bulk-upload',
    deleteProduct:(id:string)=>`admin/product/${id}`,
    updateEcommerceStatus:(id:string)=> `admin/product/${id}/ecommerce/status`
}
export default Product