import React, { useState, useEffect, useContext } from "react";
import Wrapper from "../../../../components/common/Wrapper/Wrapper";
import organisms from "../../../../components/organisms";
import service from "../../../../api/services";
import { Default_Page_Limit, Default_Page_Number } from "../../../../constants/defaultValues";

import { HeaderHeading } from '../../../../Context'
import { useToasts } from "react-toast-notifications";
import { handleReportDownload } from "../../../../constants/utils";
import molecules from "../../../../components/molecules";
import constant from "../../../../constants/constant";

const { EOrderPlaced } = organisms
const { ConfirmationModal } = molecules


const Index = () => {
  const [handleDeleteModal, setHandleDeleteModal] = useState<boolean>(false)
  const [orderItemId, setOrderItemId] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [data, setData] = useState({
    initialReload: false,
    listData: null,
    limitPerPage: Default_Page_Limit,
    currentPage: Default_Page_Number,
  })
  const { addToast } = useToasts();

  const { setHeader }: any = useContext(HeaderHeading);

  const getOnlineOrderList = async () => {
    try {
      const Response = await service?.onlineOrderService.onlineOrderList({
        "page": data?.currentPage,
        "limit": data?.limitPerPage,
        "search": search,
        "status": constant?.ONLINE_ORDER_STATUS?.PENDING
      })
      if (Response?.status == 200) {
        handleChange("listData", Response?.data)
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  const handleChange = (name: string, value: any) => {
    setData((prevState: any) => ({ ...prevState, [name]: value }));
  }

  const handleView = async (id: string) => {
    try {
      const response = await service.onlineOrderService.downloadOrder(id)

      if (response.status === 200) {
         handleReportDownload(response?.data?.data?.path, "online-order-receipt", 2, "SpecxWeb")
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.response?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }

  const handleCancelOrder = async() => {
    try {
      const response = await service.onlineOrderService.cancelledOnlineOrder(orderItemId)
      if (response.status === 200 || response.status === 201) {
        setHandleDeleteModal(!handleDeleteModal)
        getOnlineOrderList()
        addToast(response?.data?.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }
    } catch (error: any) {
      console.log('error', error)
      addToast(error?.data?.message, {
        appearance: 'error',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
    }
  }


  useEffect(() => {
    setHeader("Online Order- Placed")
    handleChange("currentPage", Default_Page_Number)
    if (data?.currentPage == Default_Page_Number) {
      getOnlineOrderList()
    }
  }, [data?.limitPerPage])

  useEffect(() => {
    if (data?.initialReload) {
      getOnlineOrderList()
    } else {
      handleChange("initialReload", true)
    }
  }, [search, data?.currentPage])

  return (
    <>
      <Wrapper>
        <EOrderPlaced
          data={data}
          handleChange={(name: string, value: any) => handleChange(name, value)}
          handleView={handleView}
          handleCancel={()=>setHandleDeleteModal(!handleDeleteModal)}
          setOrderItemId={setOrderItemId}
          setSearch={(value:string)=>setSearch(value)}
        />
              {
        handleDeleteModal && <ConfirmationModal
          show={handleDeleteModal}
          handleConfirmItem={() => handleCancelOrder()}
          onHide={() => { setOrderItemId(''); setHandleDeleteModal(!handleDeleteModal); }}
          message={'cancel this order'} />
      }
      </Wrapper>
    </>
  )
}

export default Index