import { ValidationRules } from "../interface";

export const validationCouponRules: ValidationRules = {
  coupon_name: { required: true },
  code: { required: true, minLength: 5 },
  max_usage: { required: false },
  description: { required: false },
  discount_value: { required: true },
  start_date: { required: true },
  end_date: { required: true },
  coupon_type: { label: { required: true } },
  discount_type: { label: { required: true } },
};
